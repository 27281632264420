import React from "react";
import Grid from "@mui/material/Unstable_Grid2";

import {Menu} from "./components";
import Main from "./page/chat";
import {useSelector} from "react-redux";
import {Box} from "@mui/material";


function AIForecast(props) {
    const { forecastloading, forecastuser } = useSelector((state) => state.aiforecast);

    return (
        <Box
            sx={{
                width: "100vw",
            }}
        >
            <Grid
                width="100%"
                align="center"
                id="gridOfMenu"
                sx={{}}
            >
                <Menu />

            </Grid>
            <Grid
                id="mainChatPage"
                align="center"
                justify="center"
                sx={{
                    height: "100%",
                    // width: "100%",
                    paddingTop: "30px"
                    // marginLeft: "30px",
                    // outline: "1px solid blue"
                }}
            >
                <Main />
            </Grid>
        </Box>
    );
}

export default AIForecast;
