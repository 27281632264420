import React, { useEffect, useReducer, useRef, memo, forwardRef, useImperativeHandle, Fragment, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setLoading } from "../redux/loading";
import { useDispatch, useSelector } from "react-redux";
import { request } from "../../../helpers/requests";
import TextField from "@mui/material/TextField";
import { Box, Button, InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import { createSlice } from "@reduxjs/toolkit";
import { Sun } from "../assets";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Typography from "@mui/material/Typography";
import PrettyPrintJson from "../../../helpers/PrettyPrintJson";
import New from "./new";
import Forecast from "./forecast";



let messagesSlice = createSlice({
  name: 'forecastmessages',
  initialState: {
    prompt: '',
    content: '',
    _id: null,
    latest: {
      prompt: '',
      content: ''
    },
    all: []
  },
  reducers: {
    clearPrompt: (state) => {
      return {
        ...state,
        prompt: '',
      }
    },
    emptyAllRes: () => {
      return {
        prompt: '',
        content: '',
        _id: null,
        latest: {
          prompt: '',
          content: ''
        },
        all: []
      }
    },
    addList: (state, { payload }) => {
      const { _id, items } = payload
      state._id = _id
      state.all = items
      return state
    },
    insertNew: (state, { payload }) => {
      const { chatsId, content = null,
        resume = false, fullContent = null,
        _id = null, prompt = null } = payload

      if (_id) {
        state._id = _id
      }

      state.latest.id = chatsId

      if (prompt) {
        state.latest.prompt = prompt
      }

      const addToList = (latest) => {
        if (state['all'].find(obj => obj.id === latest.id)) {
          state['all'].forEach(obj => {
            if (obj.id === latest.id) {
              obj.content = latest.content
            }
          })
        } else {
          state['all'].push(latest)
        }
      }

      if (content && resume) {
        state.latest.content += content
        addToList(state.latest)

      } else if (content) {
        state.latest.content = content
        addToList(state.latest)
      }

      if (fullContent) {
        state.content = fullContent
      }

      return state
    },
    livePrompt: (state, { payload }) => {
      state.prompt = payload
      return state
    }
  }
})

export const { clearPrompt, emptyAllRes, insertNew, livePrompt, addList } = messagesSlice.actions

const reducer = (state, { type, status }) => {
  switch (type) {
    case "chat":
      return {
        chat: status,
        loading: status,
        resume: status,
        actionBtns: false,
      };
    case "error":
      return {
        chat: true,
        error: status,
        resume: state.resume,
        loading: state.loading,
        actionBtns: state.actionBtns,
      };
    case "resume":
      return {
        chat: true,
        resume: status,
        loading: status,
        actionBtns: true,
      };
    default:
      return state;
  }
};



//Input Area
const InputArea = ({ status, forecastRef, stateAction }) => {
  const dispatch = useDispatch();

  // Separate states for the two fields
  const [website, setWebsite] = useState('');
  const [budget, setBudget] = useState('');

  const FormHandle = async () => {
    if (website.length > 0 && budget.length > 0) {
      stateAction({ type: "chat", status: true });

      const combinedPrompt = `Website: ${website}, Budget: ${budget}`; // Combine inputs
      const chatsId = Date.now();

      dispatch(insertNew({ id: chatsId, content: "", prompt: combinedPrompt }));
      forecastRef?.current?.clearResponse();

      try {
        const res = await request("post", "/aiforecast/forecast", {
          prompt: combinedPrompt,
        });

        if (res?.data) {
          const { _id, content } = res.data.data;
          dispatch(insertNew({ _id, fullContent: content, chatsId }));
          forecastRef?.current?.loadResponse(stateAction, content, chatsId);
        }
      } catch (err) {
        console.error(err);
        stateAction({ type: "error", status: true });
      } finally {
        stateAction({ type: "error", status: false });
      }
    }
  };

  return (
    <Box
      id="inputContainerInnerBox"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "16px",
      }}
    >
      <TextField
        id="forecastTextArea"
        label="Enter your website"
        variant="outlined"
        multiline
        maxRows={1}
        value={website}
        onChange={(e) => setWebsite(e.target.value)} // Update website state
        sx={{
          maxWidth: "400px",
          width: "100%",
          backgroundColor: "#fff",
        }}
      />
      <TextField
        id="forecastbudgetTextArea"
        label="Enter your budget per month"
        variant="outlined"
        multiline
        maxRows={1}
        value={budget}
        onChange={(e) => setBudget(e.target.value)} // Update budget state
        sx={{
          maxWidth: "400px",
          width: "100%",
          backgroundColor: "#fff",
        }}
      />
      <Button
        variant="contained"
        onClick={FormHandle}
        sx={{
          width: "200px",
        }}
      >
        Generate Forecast
      </Button>
    </Box>
  );
};



const Main = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forecastRef = useRef();
  const { forecastuser } = useSelector((state) => state.aiforecast);
  const { id = null } = useParams();

  const [status, stateAction] = useReducer(reducer, {
    chat: false,
    error: false,
    actionBtns: false,
  });


  useEffect(() => {
    if (forecastuser) {
      dispatch(emptyAllRes());
      setTimeout(() => {
        if (id) {
          const getSaved = async () => {
            let res = null;
            try {
              res = await request("post", "/aiforecast/saved", {
                chatId: id,
              });
            } catch (err) {
              console.log(err);
              if (err?.response?.data?.status === 404) {
                navigate("/404");
              } else {
                alert(err);
                // dispatch(setLoading(false));
              }
            } finally {
              if (res?.data) {
                dispatch(addList({ _id: id, items: res?.data?.data }));
                stateAction({ type: "resume", status: false });
                dispatch(setLoading(false));
              }
            }
          };

          getSaved();
        } else {
          stateAction({ type: "chat", status: false });
          dispatch(setLoading(false));
        }
      }, 1000);
    }
  }, [location]);

  return (
    <Box id="chatPageContainer" sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      {status.chat ?
        <Box
          id="currentChat"
          sx={{
            maxWidth: "1400px",
            marginLeft: "-50px",
            position: "absolute",
            display: "block",
          }}
        >
          {/* Forecast - The area where the response is displayed */}
          <Forecast ref={forecastRef} error={status.error} />
        </Box>
        :
        <New />
      }
      <InputArea
        status={status}
        forecastRef={forecastRef}
        stateAction={stateAction}
      />
    </Box>
  );
};


export default Main;
