import { memo } from "react";
import { Sun } from "../assets";



const New = memo(() => {
    return (
      <>
        <div>
          <h1 className='title currentColor'>SEO Forecast</h1>
        </div>
  
        <div>
          <div>
            <div>
              <Sun />
              <h4 className='currentColor'>Create an estimated SEO forecast powered by SEO data and AI</h4>
            </div>
          </div>
        </div>
      </>
    )
  })

  export default New;