import { Box } from "@mui/material";
import { useRef, forwardRef, useImperativeHandle, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Fragment } from "react";




const Forecast = forwardRef(({ error }, ref) => {

    const contentRef = useRef();
  
    const { forecastmessages } = useSelector((state) => state.aiforecast);
    const { latest, content, all } = forecastmessages;
  
    const loadResponse = (stateAction, response = content, chatsId = latest?.id) => {
      clearInterval(window.interval);
      stateAction({ type: "resume", status: true });
  
      let index = 0;
      const appendText = () => {
        if (index < response.length && contentRef?.current) {
          contentRef.current.innerHTML = response.slice(0, index + 1);
          contentRef.current.scrollTop = contentRef.current.scrollHeight; // Keep the view scrolled down
          index++;
        } else {
          clearInterval(window.interval);
        }
      };
  
      window.interval = setInterval(appendText, 20); // Keep the interval to animate text appearing
    };
  
  
    useImperativeHandle(ref, () => ({
      loadResponse,
      clearResponse: () => {
        if (contentRef?.current) {
          contentRef.current.innerHTML = "";
        }
      },
    }));
  
  
  
  
    // Define common styles
    const userPromptStyle = {
      textAlign: "left",
      paddingTop: "55px",
      paddingLeft: "35px",
      paddingBottom: "80px",
      // overflow: "hidden",
      maxWidth: "100%", /* Prevent overflow beyond the parent container */
      wordWrap: "break-word" /* Ensure long words don't cause container to expand */
    };
  
    const iconStyle = {
      marginTop: "-40px",
    };
  
    const chatResponseStyle = {
      ...userPromptStyle,
      paddingTop: "55px",
      paddingLeft: "35px",
      paddingBottom: "80px",
      maxWidth: "100%",
      wordWrap: "break-word",
      backgroundColor: "rgba(101, 101, 101, 0.06)",
      height: "auto",
      minHeight: "100px", /* Stabilizes initial size */
      maxHeight: "400px",
      overflowY: "auto",
      transition: "height 0.2s ease" /* Only animate height changes */
    };
  
    const accountCircleStyle = {
      height: "41px",
      width: "41px",
    };
  
  
  
    return (
      <Box className="Chat" sx={{
        height: "100%", marginBottom: "170px"
      }}>
        {all?.filter(obj => !obj.id || obj.id !== latest?.id)?.map((obj, key) => (
          <Fragment key={key}>
            <Box className="chatResponse" sx={chatResponseStyle}>
            </Box>
          </Fragment>
        ))}
  
        {latest?.prompt?.length > 0 && (
          <Fragment>
            <Box className="chatResponse" sx={chatResponseStyle}>
              <span ref={contentRef.innerHTML} />
              {console.log(contentRef)}
            </Box>
          </Fragment>
        )}
      </Box>
    );
  });
  
  export default Forecast;